import { RentalSearch } from '@/graphql/types-and-hooks';
import { TFlexibleDateRange } from '@/services/analytics/search/types';
import { ERentalType } from '@/services/analytics/types';
import { EShareMethodType } from '@/services/types/referral/referral';
import { ERentalCategory, IData } from '@/services/types/search/rentals/id';
import {
  IRentalTile,
  isCampgroundTile,
  ISearchResultTile,
  mapRentalToOriginalPrice,
} from '@/utility/mapSearchResultToTile';

import { IQuote } from '../../types/core/quotes';
import { EProjectSection, trackEventV2 } from '../trackEventV2';
import { EListingEventName, EListingSource, IListingEvent } from './types';

export const trackListingViewedEvent = (listingEventData: IListingEvent) => {
  trackEventV2(EListingEventName.LISTING_VIEWED, EProjectSection.MARKETPLACE, {
    ...listingEventData,
  });
};

// event from listing data
export const trackListingPageSelectedEvent = ({
  listingData,
  flexibleDateRange,
  eventSource = 'trips',
  listingPlacement = 1,
  currentPageViewed = 1,
  isStay = false,
  isCampsite = false,
  campsiteCategoryType,
}: {
  listingData: IData | RentalSearch;
  eventSource?: string;
  listingPlacement?: number;
  currentPageViewed?: number;
  flexibleDateRange: TFlexibleDateRange;
  isStay: boolean;
  isCampsite?: boolean;
  campsiteCategoryType?: string;
}) => {
  trackEventV2(EListingEventName.LISTING_SELECTED, EProjectSection.MARKETPLACE, {
    rentalID: listingData.id,
    rentalType: (() => {
      if (isCampsite) return campsiteCategoryType || ERentalType.CAMPSITE;
      if (isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground: isCampsite,
    source: eventSource,
    reviewsCount: listingData.reviews_num || 0,
    reviewsAverage: listingData.average_reviews?.score || 0,
    isInstabook: listingData.instant_book,
    pricePerNight: listingData.price_per_day ? listingData.price_per_day / 100 : 0,
    discountPercentOff: 0,
    listingPlacement,
    currentPageViewed,
    flexibleDateRange,
    blendLogicSource: listingData.blend_logic_source || null,
    basePricePerNight: mapRentalToOriginalPrice(listingData) / 100,
    ...(isCampsite &&
      'campsite_category' in listingData && {
        displaySiteCategoryType: listingData.campsite_category?.display_category_type,
        siteCategoryType: listingData?.campsite_category?.category_type,
      }),
  });
};

// event from rental data
export const trackListingSelectedEvent = ({
  rental,
  eventSource,
  listingPlacement,
  currentPageViewed = 1,
  flexibleDateRange,
  carouselAlgoSlug,
  promoSource,
  tab,
}: {
  rental: ISearchResultTile;
  eventSource: EListingSource;
  listingPlacement: number;
  currentPageViewed?: number;
  flexibleDateRange?: TFlexibleDateRange;
  carouselAlgoSlug?: string;
  promoSource?: string | null;
  tab?: string;
  wishlistID?: number;
  wishlistName?: string;
  wishlistCreatorID?: number | null;
}) => {
  if (isCampgroundTile(rental)) return;

  trackEventV2(EListingEventName.LISTING_SELECTED, EProjectSection.MARKETPLACE, {
    rentalID: rental.id,
    rentalType: (() => {
      if (rental.isCampsite) return rental.campsiteCategoryType || ERentalType.STAY;
      if (rental.isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground: !!rental.isCampsite,
    source: eventSource,
    reviewsCount: rental.details?.ratingQtd || 0,
    reviewsAverage: rental.details?.ratingNumber || 0,
    isInstabook: rental.instantBook,
    pricePerNight: (rental.price?.currentPrice || 0) / 100,
    discountPercentOff: rental.price?.discount || 0,
    listingPlacement,
    currentPageViewed,
    flexibleDateRange,
    carouselAlgoSlug,
    blendLogicSource: rental.blendLogicSource,
    basePricePerNight: (rental.price?.originalPrice || 0) / 100,
    promoSource: promoSource || null,
    ...(!!rental.isCampsite &&
      rental.displaySiteCategoryType &&
      rental.siteCategoryType && {
        displaySiteCategoryType: rental.displaySiteCategoryType,
        siteCategoryType: rental.siteCategoryType,
      }),
    ...(tab && { tab }),
    ...(rental.wishlistID && { wishlistID: rental.wishlistID }),
    ...(rental.wishlistName && { wishlistName: rental.wishlistName }),
    ...(rental.wishlistCreatorID && { wishlistCreatorID: rental.wishlistCreatorID }),
  });
};

// tracking opening the listing gallery itself
export const trackListingPageGalleryViewedEvent = (
  listingData: IData,
  eventSource: string,
  listingPlacement: number,
  imagePlacement: number,
  imageCount: number,
  currentPageViewed = 1,
) => {
  const isCampground = listingData.rental_category === ERentalCategory.CAMPGROUND;
  const isStay = listingData.rental_category === ERentalCategory.STAY;

  trackEventV2(EListingEventName.LISTING_GALLERY_VIEWED, EProjectSection.MARKETPLACE, {
    rentalID: listingData.id,
    rentalType: (() => {
      if (isCampground) return listingData.campsite_category?.category_type || ERentalType.CAMPSITE;
      if (isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground,
    source: eventSource,
    reviewsCount: listingData.reviews_num || 0,
    reviewsAverage: listingData.average_reviews?.score || 0,
    isInstabook: listingData.instant_book,
    pricePerNight: listingData.price_per_day / 100 || 0,
    discountPercentOff: 0,
    listingPlacement,
    imagePlacement,
    totalImagesAvailable: imageCount,
    isGalleryView: true,
    currentPageViewed,
    blendLogicSource: listingData.blend_logic_source || null,
  });
};

// tracking image viewed event with rental
export const trackListingImageViewedEvent = (
  rental: ISearchResultTile,
  eventSource: string,
  listingPlacement: number,
  imagePlacement: number,
  currentPageViewed = 1,
) => {
  if (isCampgroundTile(rental)) return;
  trackEventV2(EListingEventName.LISTING_IMAGE_VIEWED, EProjectSection.MARKETPLACE, {
    rentalID: rental.id,
    rentalType: (() => {
      if (rental.isCampsite) return rental.campsiteCategoryType || ERentalType.STAY;
      if (rental.isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground: !!rental.isCampsite,
    source: eventSource,
    reviewsCount: rental.details?.ratingQtd || 0,
    reviewsAverage: rental.details?.ratingNumber || 0,
    isInstabook: rental.instantBook,
    pricePerNight: (rental.price?.currentPrice || 0) / 100,
    discountPercentOff: rental.price?.discount || 0,
    listingPlacement,
    imagePlacement,
    totalImagesAvailable: rental.photoUrl?.length || 0,
    isGalleryView: false,
    currentPageViewed,
    blendLogicSource: rental.blendLogicSource,
  });
};

export const trackListingSharedEvent = (
  listingData: IData,
  shareMethod: EShareMethodType,
  isOwnerShare: boolean,
  isOutdoorsyStay: boolean,
  currentPageViewed = 1,
) => {
  const isCampground = listingData.rental_category === ERentalCategory.CAMPGROUND;
  const isStay = listingData.rental_category === ERentalCategory.STAY;

  trackEventV2(EListingEventName.LISTING_SHARED, EProjectSection.MARKETPLACE, {
    rentalID: listingData.id,
    rentalType: (() => {
      if (isCampground) return listingData.campsite_category?.category_type || ERentalType.CAMPSITE;
      if (isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground,
    reviewsCount: listingData.reviews_num || 0,
    reviewsAverage: listingData.average_reviews?.score || 0,
    isInstabook: listingData.instant_book,
    pricePerNight: listingData.price_per_day / 100 || 0,
    shareMethod,
    isOwnerShare,
    currentPageViewed,
    isOutdoorsyStay,
  });
};

export const trackListingQuoteUpdatedEvent = (prevQuote: IQuote, newQuote: IQuote) => {
  const prevTotal = prevQuote.total || 0;
  const rentalTypeProps = (() => {
    if (newQuote.rental_summary?.campsite_summary) {
      return {
        rentalType: newQuote.rental_summary.campsite_summary.type || ERentalType.CAMPSITE,
        isCampground: true,
      };
    }

    if (newQuote.rental_summary?.stay_summary) {
      return {
        rentalType: ERentalType.STAY,
      };
    }

    return {
      rentalType: ERentalType.RV,
      handoffMethod: newQuote.insurance_bundle?.delivery ? 'delivery' : 'pickup',
      insuranceCoverage: newQuote.insurance_bundle?.name,
      insuranceCost: (newQuote.insurance_bundle?.total_price || 0) / 100,
    };
  })();

  trackEventV2(EListingEventName.LISTING_QUOTE_UPDATED, EProjectSection.MARKETPLACE, {
    rentalID: newQuote.rental_summary?.id,
    isInstabook: newQuote.is_request_less_than_minimum_days !== true,
    pricePerNight: newQuote.calculated_day_price / 100,
    basePricePerNight: newQuote.original_day_price / 100,
    discountPercentOff:
      Math.floor(
        (newQuote.original_day_price - newQuote.calculated_day_price) /
          newQuote.calculated_day_price,
      ) * 100,
    totalNights: newQuote.duration,
    departureDate: parseInt((new Date(newQuote.from).getTime() / 1000).toFixed(0)),
    returnDate: parseInt((new Date(newQuote.to).getTime() / 1000).toFixed(0)),
    addOnsCost: newQuote.addons
      ? newQuote.addons.reduce((acc, addon) => acc + addon.total, 100) / 100
      : null,
    addOnsSelected: newQuote.addons ? newQuote.addons.map(addon => addon.name) : null,
    mileageFeesCost: newQuote.fees_total / 100,
    totalCost: newQuote.total / 100,
    currencyType: newQuote.presentment_currency,
    previousCost: prevTotal / 100,
    ...rentalTypeProps,
    isOutdoorsyStay: !!newQuote.sibling_quote_group_id,
  });
};

interface IMessageSendEvent {
  rentalID?: number;
  bookingID?: number;
  quickResponse?: string;
  messageCharacterCount: number;
  isConversationStarter?: boolean;
  totalMessageCount?: number;
  lastMessageSent?: string;
  tripStatus?: string;
  recipientUserID?: number;
  sendingUserActor: 'owner' | 'renter';
  isDealer?: boolean;
}

export const trackMessageSentEvent = (event: IMessageSendEvent) => {
  trackEventV2(EListingEventName.MESSAGE_SEND, EProjectSection.MARKETPLACE, {
    rentalID: event.rentalID || null,
    bookingID: event.bookingID || null,
    quickResponse: event.quickResponse || null,
    messageCharacterCount: event.messageCharacterCount,
    isConversationStarter:
      event.isConversationStarter != undefined ? event.isConversationStarter : null,
    totalMessageCount: event.totalMessageCount || null,
    lastMessageSent: event.lastMessageSent || null,
    tripStatus: event.tripStatus || null,
    recipientUserID: event.recipientUserID || null,
    sendingUserActor: event.sendingUserActor,
    isDealer: event.isDealer ?? null,
  });
};

export const trackListingSavedEvent = (
  listingData: IData,
  listingPlacement: number,
  source: string,
  isOutdoorsyStay = false,
  currentPageViewed = 1,
) => {
  const isCampground = listingData.rental_category === ERentalCategory.CAMPGROUND;
  const isStay = listingData.rental_category === ERentalCategory.STAY;

  trackEventV2(EListingEventName.LISTING_FAVORITED, EProjectSection.MARKETPLACE, {
    rentalID: listingData.id,
    rentalType: (() => {
      if (isCampground) return listingData.campsite_category?.category_type || ERentalType.CAMPSITE;
      if (isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground,
    reviewsCount: listingData.reviews_num || 0,
    reviewsAverage: listingData.average_reviews?.score || 0,
    isInstabook: listingData.instant_book,
    pricePerNight: (listingData.price_per_day || 0) / 100,
    listingPlacement,
    source,
    currentPageViewed,
    blendLogicSource: listingData.blend_logic_source || null,
    isOutdoorsyStay,
  });
};

export const trackListingUnsavedEvent = (
  listingData: IData,
  listingPlacement: number,
  source: string,
  isOutdoorsyStay = false,
  currentPageViewed = 1,
) => {
  const isCampground = listingData.rental_category === ERentalCategory.CAMPGROUND;
  const isStay = listingData.rental_category === ERentalCategory.STAY;

  trackEventV2(EListingEventName.LISTING_UNFAVORITED, EProjectSection.MARKETPLACE, {
    rentalID: listingData.id,
    rentalType: (() => {
      if (isCampground) return listingData.campsite_category?.category_type || ERentalType.CAMPSITE;
      if (isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground,
    reviewsCount: listingData.reviews_num || 0,
    reviewsAverage: listingData.average_reviews?.score || 0,
    isInstabook: listingData.instant_book,
    pricePerNight: (listingData.price_per_day || 0) / 100,
    listingPlacement,
    source,
    currentPageViewed,
    blendLogicSource: listingData.blend_logic_source || null,
    isOutdoorsyStay,
  });
};

export const trackListingFavoritedEvent = (
  rental: IRentalTile,
  listingPlacement: number,
  source: EListingSource,
  isOutdoorsyStay = false,
  currentPageViewed = 1,
  carouselAlgoSlug?: string,
  wishlistID?: number,
  wishlistName?: string,
  wishlistItemCount?: number,
  listingFavoriteCount?: number,
  wishlistCreatorID?: number,
) => {
  trackEventV2(EListingEventName.LISTING_FAVORITED, EProjectSection.MARKETPLACE, {
    rentalID: rental.id,
    rentalType: (() => {
      if (rental.isCampsite) return rental.campsiteCategoryType || ERentalType.STAY;
      if (rental.isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground: !!rental.isCampsite,
    reviewsCount: rental.details?.ratingQtd || 0,
    reviewsAverage: rental.details?.ratingNumber || 0,
    isInstabook: rental.instantBook,
    pricePerNight: (rental.price?.currentPrice || 0) / 100,
    listingPlacement,
    source,
    carouselAlgoSlug,
    currentPageViewed,
    blendLogicSource: rental.blendLogicSource,
    isOutdoorsyStay,
    wishlistID,
    wishlistName,
    wishlistItemCount,
    listingFavoriteCount,
    wishlistCreatorID: wishlistCreatorID || null,
  });
};

export const trackListingUnfavoritedEvent = (
  rental: IRentalTile,
  listingPlacement: number,
  source: EListingSource,
  isOutdoorsyStay = false,
  currentPageViewed = 1,
  carouselAlgoSlug?: string,
  wishlistID?: number,
  wishlistName?: string,
  wishlistItemCount?: number,
  listingFavoriteCount?: number,
  wishlistCreatorID?: number,
) => {
  trackEventV2(EListingEventName.LISTING_UNFAVORITED, EProjectSection.MARKETPLACE, {
    rentalID: rental.id,
    rentalType: (() => {
      if (rental.isCampsite) return rental.campsiteCategoryType || ERentalType.STAY;
      if (rental.isStay) return ERentalType.STAY;
      return ERentalType.RV;
    })(),
    isCampground: !!rental.isCampsite,
    reviewsCount: rental.details?.ratingQtd || 0,
    reviewsAverage: rental.details?.ratingNumber || 0,
    isInstabook: rental.instantBook,
    pricePerNight: (rental.price?.currentPrice || 0) / 100,
    listingPlacement,
    source,
    carouselAlgoSlug,
    currentPageViewed,
    blendLogicSource: rental.blendLogicSource,
    isOutdoorsyStay,
    wishlistID,
    wishlistName,
    wishlistItemCount,
    listingFavoriteCount,
    wishlistCreatorID: wishlistCreatorID || null,
  });
};

export const trackListingAvailabilityCheckedEvent = (event: IListingEvent) => {
  trackEventV2(EListingEventName.LISTING_AVAILABILITY_CHECKED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackListingSeeAllReviewsCheckedEvent = (event: IListingEvent) => {
  trackEventV2(EListingEventName.SEE_ALL_REVIEWS_CHECKED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};

export const trackDiscountPromotionAcceptedEvent = (quoteData: IQuote, isDealer: boolean) => {
  trackEventV2(EListingEventName.DISCOUNT_PROMOTION_ACCEPTED, EProjectSection.MARKETPLACE, {
    bookingDepartureDate: parseInt((new Date(quoteData.from).getTime() / 1000).toFixed(0)),
    bookingReturnDate: parseInt((new Date(quoteData.to).getTime() / 1000).toFixed(0)),
    isOwner: !isDealer,
    lengthOfStay: quoteData.duration,
  });
};

interface IMessageHostClickedEvent {
  rentalID?: number;
  recipientUserID?: number;
  isDealer?: boolean;
}

export const trackMessageHostClickedEvent = (event: IMessageHostClickedEvent) => {
  trackEventV2(EListingEventName.LISTING_MESSAGE_HOST_CLICKED, EProjectSection.MARKETPLACE, {
    rentalID: event.rentalID || null,
    recipientUserID: event.recipientUserID || null,
    isDealer: event.isDealer ?? null,
  });
};

interface IPriceBreakdownPreviewedEvent {
  basePricePerNight: number;
  currentPageViewed: number;
  deliveryFee: number;
  discountPercentOff: number;
  duration: number;
  serviceFee: number;
  insurance: number;
  isCampground: boolean;
  isInstabook: boolean;
  mapStatus: string;
  markersOnMap: number;
  prepFee: number;
  pricePerNight: number;
  rentalAmount: number;
  rentalAmountPerDay: number;
  rentalID: number;
  rentalType: string;
  total: number;
  useDayPricing: boolean;
}

export const trackPriceBreakdownPreviewedEvent = (event: IPriceBreakdownPreviewedEvent) => {
  trackEventV2(EListingEventName.PRICE_BREAKDOWN_PREVIEWED, EProjectSection.MARKETPLACE, {
    ...event,
  });
};
