// this interface keeps in line with the IFavorite to reuse the funtionality in maprentaltotiles
// added_at is not used for wishlists
interface IWishlistedRental {
  added_at: string;
  id: number; // wishlist ID
  rental_id: number;
  wishlist_creator_id?: number;
  wishlist_name?: string;
  wishlist_id?: number;
}

interface IWishlistsState {
  data: IWishlistedRental[];
  isFeatureEnabled: boolean | null;
}

const initialState: IWishlistsState = {
  data: [],
  isFeatureEnabled: null,
};

// Action Types
const SET_WISHLISTED_RENTALS = 'wishlists/SET_DATA';
const SET_FEATURE_ENABLED = 'wishlists/SET_FEATURE_ENABLED';

interface ISetWishlistedRentalsAction {
  type: typeof SET_WISHLISTED_RENTALS;
  payload: IWishlistedRental[];
}

interface ISetFeatureEnabledAction {
  type: typeof SET_FEATURE_ENABLED;
  payload: boolean;
}

type TWishlistsAction = ISetWishlistedRentalsAction | ISetFeatureEnabledAction;

// Action Creators

export const setWishlistedRentals = (data: IWishlistedRental[]): ISetWishlistedRentalsAction => ({
  type: SET_WISHLISTED_RENTALS,
  payload: data,
});

export const setWishlistsFeatureEnabled = (isEnabled: boolean): ISetFeatureEnabledAction => ({
  type: SET_FEATURE_ENABLED,
  payload: isEnabled,
});

export default function reducer(state = initialState, action: TWishlistsAction): IWishlistsState {
  switch (action.type) {
    case SET_WISHLISTED_RENTALS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_FEATURE_ENABLED:
      return {
        ...state,
        isFeatureEnabled: action.payload,
      };
    default:
      return state;
  }
}
