import { ThunkAction } from 'redux-thunk';

import apiRequest from '@/services/apiRequest';
import { IBundleCampground } from '@/services/types/bundles/id';
import { getCoreApi } from '@/utility/getCoreApi';
import { logger } from '@/utility/logger';
import { IAction } from '@/utility/redux/action';

import { TRootState } from '../rootReducer';

const BUNDLES_LOADING = 'bundles/BUNDLES_LOADING';
const SET_BUNDLES = 'bundles/SET_BUNDLES';
const SET_IS_LOADING = 'bundles/SET_IS_LOADING';

interface ISetIsLoadingAction {
  type: typeof SET_IS_LOADING;
  payload: boolean;
}
interface IRvBundlesLoadingAction extends IAction {
  type: typeof BUNDLES_LOADING;
}

interface ISetRvBundlesAction extends IAction {
  type: typeof SET_BUNDLES;
  payload: IBundleCampground[];
}

type TAction = IRvBundlesLoadingAction | ISetRvBundlesAction | ISetIsLoadingAction;

const setBundles = (payload: IBundleCampground[]): ISetRvBundlesAction => ({
  type: SET_BUNDLES,
  payload,
});

export const getBundles =
  (): ThunkAction<Promise<void>, TRootState, void, ISetRvBundlesAction | IRvBundlesLoadingAction> =>
  async (dispatch, _) => {
    dispatch<IRvBundlesLoadingAction>({
      type: BUNDLES_LOADING,
    });

    try {
      const response = await apiRequest<IBundleCampground[]>(
        {
          url: `${getCoreApi()}/landing-page-campgrounds`,
          method: 'GET',
        },
        true,
      );

      dispatch(setBundles(response));
    } catch (error) {
      logger.captureExceptionWithDatadog(error, { url: error.request?._currentUrl });
    }
  };

interface IState {
  data: IBundleCampground[] | null;
  isLoading: boolean;
}

export const initialState: IState = {
  data: null,
  isLoading: true,
};

export default function reducer(state = initialState, action: TAction): IState {
  switch (action.type) {
    case BUNDLES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_BUNDLES:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return { ...state };
  }
}
