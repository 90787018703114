import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { defaultLocale } from '@/config/locales';
import addons, { initialState as addonsInitialState } from '@/redux/modules/addons';

import auth, { initialAuthState } from './modules/auth';
import availability, { initialState as availabilityInitialState } from './modules/availability';
import availableServices, {
  initialState as availableServicesInitialState,
} from './modules/availableServices';
import booking, { initialState as bookingInitialState } from './modules/booking/reducer';
import bookings, { initialState as bookingsInitialState } from './modules/bookings/reducer';
import bookingsReview, {
  initialState as bookingsReviewInitialState,
} from './modules/bookingsReview/reducer';
import bundledListings, {
  initialState as bundledListingsInitialState,
} from './modules/bundledListings';
import bundles, { initialState as bundlesInitialState } from './modules/bundles';
import campgroundListing, {
  initialState as campgroundListingInitialState,
} from './modules/campgroundListing';
import checkout, { initialState as checkoutInitialState } from './modules/checkout';
import cookies, { initialState as cookiesInitialState } from './modules/cookies';
import creditCards, { initialState as creditCardsInitialState } from './modules/creditCards';
import dateProposals, {
  initialDryDateProposalState,
  initialPostDateProposalState,
} from './modules/dateProposal';
import dealerLocation, {
  initialState as dealerLocationInitialState,
} from './modules/dealerLocation';
import delivery, { initialState as deliveryInitialState } from './modules/delivery';
import favorites, { initialState as favoritesInitialState } from './modules/favorites';
import filterSlides, { initialState as filterSlidesInitialState } from './modules/filterSlides';
import globalHeader, { initialState as globalHeaderInitialState } from './modules/globalHeader';
import insuranceBundles, {
  initialState as insuranceBundlesInitialState,
} from './modules/insuranceBundles';
import listing, { initialState as listingInitialState } from './modules/listing';
import listingVehicleType, {
  initialState as listingVehicleTypeInitialState,
} from './modules/listingVehicleType';
import mapboxPlace, { initialState as mapboxPlaceInitialState } from './modules/mapboxPlace';
import messages, { initialState as messagesInitialState } from './modules/messages';
import modalReducer, { initialModalState } from './modules/modal/reducer';
import nearbyCampgrounds, {
  initialState as nearbyCampgroundsInitialState,
} from './modules/nearbyCampgrounds';
import owner, { initialState as ownerInitialState } from './modules/owner/reducer';
import pricingRules, { initialState as pricingRulesInitialState } from './modules/pricingRules';
import promoQueryParams, {
  initialState as promoQueryParamsInitialState,
} from './modules/promoQueryParams';
import proposals, { initialPatchProposalState } from './modules/proposal';
import queryParams, { initialState as queryParamsInitialState } from './modules/queryParams';
import quote, { initialState as quoteInitialState } from './modules/quote';
import rentalItems, { initialState as rentalItemsInitialState } from './modules/rentalItems';
import rentalNotes, { initialState as rentalNotesInitialState } from './modules/rentalNotes';
import reviews, { initialState as reviewsInitialState } from './modules/reviews';
import routerLocale from './modules/routerLocale';
import savedRentals, { initialState as savedRentalsInitialState } from './modules/savedRentals';
import search, { initialState as searchInitialState } from './modules/search';
import searchForm, { initialState as searchFormInitialState } from './modules/searchForm';
import similarRentals, {
  initialState as similarRentalsInitialState,
} from './modules/similarRentals';
import stayStates, { initialState as stayStatesInitialState } from './modules/stayStates';
import tags, { initialState as tagsInitialState } from './modules/tags/reducer';
import wishlists from './modules/wishlists';
import rentals, { initialState as rentalsInitialState } from './reducers/rentalsReducer';

const rootReducer = combineReducers({
  modal: modalReducer,
  dryDateProposal: dateProposals.dryDateProposalReducer,
  postDateProposal: dateProposals.postDateProposalReducer,
  patchProposal: proposals.patchProposalReducer,
  addons,
  availability,
  auth,
  checkout,
  booking,
  bookings,
  bookingsReview,
  cookies,
  creditCards,
  delivery,
  favorites,
  filterSlides,
  globalHeader,
  insuranceBundles,
  listing,
  dealerLocation,
  mapboxPlace,
  messages,
  pricingRules,
  queryParams,
  quote,
  rentalItems,
  rentalNotes,
  rentals,
  reviews,
  search,
  searchForm,
  savedRentals,
  similarRentals,
  nearbyCampgrounds,
  tags,
  owner,
  routerLocale,
  stayStates,
  listingVehicleType,
  campgroundListing,
  bundledListings,
  promoQueryParams,
  availableServices,
  wishlists,
  bundles,
});

export type TRootState = ReturnType<typeof rootReducer>;

export type TAppDispatch = ThunkDispatch<TRootState, any, AnyAction>;

export const initialRootState: TRootState = {
  modal: initialModalState,
  dryDateProposal: initialDryDateProposalState,
  postDateProposal: initialPostDateProposalState,
  patchProposal: initialPatchProposalState,
  addons: addonsInitialState,
  availability: availabilityInitialState,
  auth: initialAuthState,
  checkout: checkoutInitialState,
  booking: bookingInitialState,
  bookings: bookingsInitialState,
  bookingsReview: bookingsReviewInitialState,
  cookies: cookiesInitialState,
  creditCards: creditCardsInitialState,
  pricingRules: pricingRulesInitialState,
  delivery: deliveryInitialState,
  favorites: favoritesInitialState,
  filterSlides: filterSlidesInitialState,
  globalHeader: globalHeaderInitialState,
  insuranceBundles: insuranceBundlesInitialState,
  listing: listingInitialState,
  campgroundListing: campgroundListingInitialState,
  bundledListings: bundledListingsInitialState,
  dealerLocation: dealerLocationInitialState,
  messages: messagesInitialState,
  mapboxPlace: mapboxPlaceInitialState,
  queryParams: queryParamsInitialState,
  quote: quoteInitialState,
  rentalItems: rentalItemsInitialState,
  rentalNotes: rentalNotesInitialState,
  rentals: rentalsInitialState,
  reviews: reviewsInitialState,
  search: searchInitialState,
  searchForm: searchFormInitialState,
  savedRentals: savedRentalsInitialState,
  similarRentals: similarRentalsInitialState,
  nearbyCampgrounds: nearbyCampgroundsInitialState,
  tags: tagsInitialState,
  owner: ownerInitialState,
  routerLocale: defaultLocale,
  stayStates: stayStatesInitialState,
  listingVehicleType: listingVehicleTypeInitialState,
  promoQueryParams: promoQueryParamsInitialState,
  availableServices: availableServicesInitialState,
  wishlists: {
    data: [],
    isFeatureEnabled: null,
  },
  bundles: bundlesInitialState,
};

export default rootReducer;
