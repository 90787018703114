export enum EPage {
  SEARCH = 'search',
  PROMOS = 'promos',
  CAMPGROUND_SEARCH = 'campground-search',
  RV_BUNDLES = 'rv-bundles',
}

export const PAGE_PATHNAME = {
  [EPage.SEARCH]: '/rv-search',
  [EPage.PROMOS]: '/promos',
  [EPage.CAMPGROUND_SEARCH]: '/campground-rental',
  [EPage.RV_BUNDLES]: '/rv-bundles',
};

export const PAGE_TITLE_SUFFIX = ' | Outdoorsy';
