import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';

export const getUserFavorites = createSelector(
  [
    (state: TRootState) => state.favorites,
    (state: TRootState) => state.wishlists.data,
    (state: TRootState) => state.wishlists.isFeatureEnabled,
  ],
  (favorites, wishlistedRentals, isFeatureEnabled) => {
    if (isFeatureEnabled) {
      // if wishlist launch flag is enabled, use wishlistedRentals
      return { data: wishlistedRentals };
    }
    return favorites;
  },
);

export const getWishlistsFeatureEnabled = createSelector(
  [(state: TRootState) => state.wishlists.isFeatureEnabled],
  isFeatureEnabled => isFeatureEnabled,
);
