import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { TCurrency } from '@/config/locales';
import { EPage, PAGE_PATHNAME } from '@/constants/pages';
import { getItemsPerPage } from '@/constants/pagination';
import {
  EFilterCategories,
  ESearchFallbackFilters,
  ESearchFilters,
  UPDATE_MAP_FILTERS,
} from '@/constants/searchFilters';
import router from '@/hooks/useRouter';
import { ISearchFormFilters, PERSIST_FORM_FILTERS } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';
import { ERentalType } from '@/services/analytics/types';
import apiRequest from '@/services/apiRequest';
import searchApiRequest, { getSearchUrl, TQueryParams } from '@/services/searchApiRequest';
import { ICampgroundData, ISearchCampground } from '@/services/types/search/campgrounds/id';
import { IData, IMeta, ISearchRentals } from '@/services/types/search/rentals/id';
import { IUserResponse, IUserResponseData } from '@/services/types/search/users/id';
import {
  IVehicle,
  IVehicleMake,
  IVehicleMakeFilterResponse,
  IVehicleModelsFilterResponse,
  IVehicleYear,
} from '@/services/types/search/vehicle';
import { filterSafeQuery } from '@/utility/filterSafeQuery';
import { getCoreApi } from '@/utility/getCoreApi';
import { isProduction } from '@/utility/isSSR';
import { logger } from '@/utility/logger';
import { IAction } from '@/utility/redux/action';

import { getCurrency } from '../selectors/currency';
import { setQueryParam } from './queryParams';

const SEARCH_PENDING = 'search/SEARCH_PENDING';
const SEARCH_RESPONSE = 'search/SEARCH_RESPONSE';
const SEARCH_FAILURE = 'search/SEARCH_FAILURE';
const SEARCH_HISTOGRAM_PENDING = 'search/SEARCH_HISTOGRAM_PENDING';
const SEARCH_HISTOGRAM_RESPONSE = 'search/SEARCH_HISTOGRAM_RESPONSE';
const SEARCH_HISTOGRAM_FAILURE = 'search/SEARCH_HISTOGRAM_FAILURE';
const SEARCH_META_PENDING = 'search/SEARCH_META_PENDING';
const SEARCH_META_RESPONSE = 'search/SEARCH_META_RESPONSE';
const SEARCH_META_FAILURE = 'search/SEARCH_META_FAILURE';
const SET_OWNER_INFO_PENDING = 'search/SET_OWNER_INFO_PENDING';
const SET_OWNER_INFO_RESPONSE = 'search/SET_OWNER_INFO_RESPONSE';
const SET_OWNER_INFO_FAILURE = 'search/SET_OWNER_INFO_FAILURE';

const GET_VEHICLES_YEAR_PENDING = 'search/GET_VEHICLES_YEAR_PENDING';
const GET_VEHICLES_YEAR_RESPONSE = 'search/GET_VEHICLES_YEAR_RESPONSE';
const GET_VEHICLES_YEAR_FAILURE = 'search/GET_VEHICLES_YEAR_FAILURE';

const GET_VEHICLES_MAKE_PENDING = 'search/GET_VEHICLES_MAKE_PENDING';
export const GET_VEHICLES_MAKE_RESPONSE = 'search/GET_VEHICLES_MAKE_RESPONSE';
const GET_VEHICLES_MAKE_FAILURE = 'search/GET_VEHICLES_MAKE_FAILURE';

const GET_VEHICLES_MAKE_FILTER_PENDING = 'search/GET_VEHICLES_MAKE_FILTER_PENDING';
const GET_VEHICLES_MAKE_FILTER_RESPONSE = 'search/GET_VEHICLES_MAKE_FILTER_RESPONSE';
const GET_VEHICLES_MAKE_FILTER_FAILURE = 'search/GET_VEHICLES_MAKE_FILTER_FAILURE';

const GET_VEHICLES_MAKE_FILTER_MODELS_PENDING = 'search/GET_VEHICLES_MAKE_FILTER_MODELS_PENDING';
const GET_VEHICLES_MAKE_FILTER_MODELS_RESPONSE = 'search/GET_VEHICLES_MAKE_FILTER_MODELS_RESPONSE';
const GET_VEHICLES_MAKE_FILTER_MODELS_FAILURE = 'search/GET_VEHICLES_MAKE_FILTER_MODELS_FAILURE';

const GET_VEHICLES_MODEL_PENDING = 'search/GET_VEHICLES_MODEL_PENDING';
export const GET_VEHICLES_MODEL_RESPONSE = 'search/GET_VEHICLES_MODEL_RESPONSE';
const GET_VEHICLES_MODEL_FAILURE = 'search/GET_VEHICLES_MODEL_FAILURE';

const GET_VEHICLE_INFO_PENDING = 'search/GET_VEHICLE_INFO_PENDING';
export const GET_VEHICLE_INFO_RESPONSE = 'search/GET_VEHICLE_INFO_RESPONSE';
const GET_VEHICLE_INFO_FAILURE = 'search/GET_VEHICLE_INFO_FAILURE';

const SET_SELECTED_FILTER = 'search/SET_SELECTED_FILTER';
const TOGGLE_FILTER = 'search/TOGGLE_FILTER';

const TRIGGER_STATIONARY_DELIVERY_FILTER = 'search/TRIGGER_STATIONARY_DELIVERY_FILTER';
const TRIGGER_DELIVERY_FILTER_FROM_AD = 'search/TRIGGER_DELIVERY_FILTER_FROM_AD';

const SET_SEARCH_QUERY = 'search/SET_SEARCH_QUERY';

export interface IRecentSearch {
  address: string;
  lat: number;
  lng: number;
}

interface IBaseSearchData {
  meta: IMeta;
  nearby?: {
    data?: IData[];
    meta?: IMeta;
  };
  randomStays?: {
    data?: IData[];
    meta?: IMeta;
  };
  recentSearches?: IRecentSearch[];
  nearbyCampgroundsForDelivery?: {
    data: ICampgroundData[];
    meta: IMeta;
  };
  nearbyCampgrounds?: {
    data: ICampgroundData[];
    meta: IMeta;
  };
}

type TRentalSearchData = IBaseSearchData & {
  data: IData[];
};

type TCampgroundSearchData = IBaseSearchData & {
  campgrounds: {
    data: ICampgroundData[];
  };
};

type TSearchData = TRentalSearchData | TCampgroundSearchData;

const isCampgroundSearchData = (searchData: TSearchData): searchData is TCampgroundSearchData => {
  return 'campgrounds' in searchData && !!searchData.campgrounds;
};

interface IGetOwnerInfoAction extends IAction {
  type: typeof SET_OWNER_INFO_PENDING;
}
interface IGetOwnerInfoResponseAction extends IAction {
  type: typeof SET_OWNER_INFO_RESPONSE;
  payload?: IUserResponseData;
}

interface IGetOwnerInfoFailAction extends IAction {
  type: typeof SET_OWNER_INFO_FAILURE;
  payload: string;
  error: true;
}

interface IVehiclesYearPendingAction extends IAction {
  type: typeof GET_VEHICLES_YEAR_PENDING;
}
interface IVehiclesYearResponseAction extends IAction {
  type: typeof GET_VEHICLES_YEAR_RESPONSE;
  payload?: IVehicleYear[];
}
interface IVehiclesYearFailAction extends IAction {
  type: typeof GET_VEHICLES_YEAR_FAILURE;
  payload: string;
  error: true;
}

interface IVehiclesMakePendingAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_PENDING;
}
interface IVehiclesMakeResponseAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_RESPONSE;
  payload?: IVehicleMake[];
}
interface IVehiclesMakeFailAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FAILURE;
  payload: string;
  error: true;
}

interface IVehiclesMakeFilterPendingAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FILTER_PENDING;
}
interface IVehiclesMakeFilterResponseAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FILTER_RESPONSE;
  payload?: IVehicleMakeFilterResponse;
}
interface IVehiclesMakeFilterFailAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FILTER_FAILURE;
  payload: string;
  error: true;
}

interface IVehiclesMakeFilterModelsPendingAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FILTER_MODELS_PENDING;
  make: string;
}
interface IVehiclesMakeFilterModelsResponseAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FILTER_MODELS_RESPONSE;
  make: string;
  payload?: IVehicleModelsFilterResponse | null;
}
interface IVehiclesMakeFilterModelsFailAction extends IAction {
  type: typeof GET_VEHICLES_MAKE_FILTER_MODELS_FAILURE;
  make: string;
  payload: string;
  error: true;
}

interface IVehiclesModelPendingAction extends IAction {
  type: typeof GET_VEHICLES_MODEL_PENDING;
}
interface IVehiclesModelResponseAction extends IAction {
  type: typeof GET_VEHICLES_MODEL_RESPONSE;
  payload?: IVehicle[];
}
interface IVehiclesModelFailAction extends IAction {
  type: typeof GET_VEHICLES_MODEL_FAILURE;
  payload: string;
  error: true;
}

interface IVehicleInfoPendingAction extends IAction {
  type: typeof GET_VEHICLE_INFO_PENDING;
}
interface IVehicleInfoResponseAction extends IAction {
  type: typeof GET_VEHICLE_INFO_RESPONSE;
  payload?: IVehicle;
}
interface IVehicleInfoFailAction extends IAction {
  type: typeof GET_VEHICLE_INFO_FAILURE;
  payload: string;
  error: true;
}

interface ISearchAction extends IAction {
  type: typeof SEARCH_PENDING;
  payload: {
    searchTimestamp: number;
  };
}

interface ISearchResponseAction extends IAction {
  type: typeof SEARCH_RESPONSE;
  payload: {
    data: TSearchData;
    recalculateMapViewport: boolean;
    searchTimestamp: number;
  };
}

interface ISearchFailAction extends IAction {
  type: typeof SEARCH_FAILURE;
  payload: { error: string; searchTimestamp: number };
  error: true;
}

interface ISearchHistogramAction extends IAction {
  type: typeof SEARCH_HISTOGRAM_PENDING;
  payload: { histogramTimestamp: number };
}

interface ISearchHistogramResponseAction extends IAction {
  type: typeof SEARCH_HISTOGRAM_RESPONSE;
  payload: { data: IMeta; histogramTimestamp: number };
}

interface ISearchHistogramFailAction extends IAction {
  type: typeof SEARCH_HISTOGRAM_FAILURE;
  payload: { data: string; histogramTimestamp: number };
  error: true;
}

interface ISearchMetaAction extends IAction {
  type: typeof SEARCH_META_PENDING;
  payload: {
    metaPreviewTimestamp: number;
  };
}

interface ISearchMetaAction extends IAction {
  type: typeof SEARCH_META_PENDING;
}

interface ISearchMetaResponseAction extends IAction {
  type: typeof SEARCH_META_RESPONSE;
  payload: { data: IMeta; metaPreviewTimestamp: number };
}

interface ISearchMetaFailAction extends IAction {
  type: typeof SEARCH_META_FAILURE;
  payload: { data: string; metaPreviewTimestamp: number };
  error: true;
}

interface ISetSelectedFilterAction extends IAction {
  type: typeof SET_SELECTED_FILTER;
  payload: EFilterCategories | null;
}

interface IToggleFilterAction extends IAction {
  type: typeof TOGGLE_FILTER;
  payload: boolean;
}

interface ITriggerStationaryDeliveryFilterAction extends IAction {
  type: typeof TRIGGER_STATIONARY_DELIVERY_FILTER;
  payload: boolean;
}
interface ITriggerDeliveryFromBannerAction extends IAction {
  type: typeof TRIGGER_DELIVERY_FILTER_FROM_AD;
  payload: boolean;
}

interface ISetSearchQueryAction extends IAction {
  type: typeof SET_SEARCH_QUERY;
  payload: string;
}

type TAction =
  | ISearchAction
  | ISearchResponseAction
  | ISearchFailAction
  | ISearchHistogramAction
  | ISearchHistogramResponseAction
  | ISearchHistogramFailAction
  | ISearchMetaAction
  | ISearchMetaResponseAction
  | ISearchMetaFailAction
  | IGetOwnerInfoAction
  | IGetOwnerInfoResponseAction
  | IGetOwnerInfoFailAction
  | ISetSelectedFilterAction
  | IToggleFilterAction
  | IVehiclesYearPendingAction
  | IVehiclesYearResponseAction
  | IVehiclesYearFailAction
  | IVehiclesMakePendingAction
  | IVehiclesMakeResponseAction
  | IVehiclesMakeFailAction
  | IVehiclesMakeFilterPendingAction
  | IVehiclesMakeFilterResponseAction
  | IVehiclesMakeFilterFailAction
  | IVehiclesMakeFilterModelsPendingAction
  | IVehiclesMakeFilterModelsResponseAction
  | IVehiclesMakeFilterModelsFailAction
  | IVehiclesModelPendingAction
  | IVehiclesModelResponseAction
  | IVehiclesModelFailAction
  | IVehicleInfoPendingAction
  | IVehicleInfoResponseAction
  | IVehicleInfoFailAction
  | ITriggerStationaryDeliveryFilterAction
  | ITriggerDeliveryFromBannerAction
  | ISetSearchQueryAction;

type TSearchFunction = (
  query: TQueryParams,
  recalculateMapViewport?: boolean,
  promoQuery?: TQueryParams | null,
  removeLoadingCallback?: () => void,
) => ThunkAction<
  Promise<void>,
  TRootState,
  void,
  ISearchAction | ISearchResponseAction | ISearchFailAction | ISearchMetaResponseAction
>;

// Used to fetch histogram data for price slider.
type TSearchHistogramFunction = (
  query: TQueryParams,
  promoQuery?: TQueryParams,
  isPriceTotalOnSrpEnabled?: boolean,
) => ThunkAction<
  Promise<void>,
  TRootState,
  void,
  ISearchHistogramAction | ISearchHistogramResponseAction | ISearchHistogramFailAction
>;

// Used to preview total results based on current filter selection.
type TSearchMetaFunction = (
  filters: Partial<ISearchFormFilters>,
  ignorePersitedFilters?: boolean,
) => ThunkAction<
  void,
  TRootState,
  void,
  ISearchMetaAction | ISearchMetaResponseAction | ISearchMetaFailAction
>;

type TApplySearchFilterFunction = (
  filter: Record<string, string | string[] | undefined>,
  ignoreRecalculateMapViewport?: boolean,
  dryRun?: boolean,
  externalRouting?: boolean,
  afterRedirectCallback?: () => void,
  ignoreExistingQueryParams?: boolean,
) => ThunkAction<
  Promise<void> | null,
  TRootState,
  void,
  ISearchAction | ISearchResponseAction | ISearchFailAction
>;

type TUnsetSearchFilterFunction = (
  filter: ESearchFilters[],
  applyFilters?: Record<string, any>,
) => ThunkAction<
  Promise<void> | null,
  TRootState,
  void,
  ISearchAction | ISearchResponseAction | ISearchFailAction
>;

type TGetOwnerInfoFunction = (
  ownerId?: string,
) => ThunkAction<
  Promise<IUserResponse | null> | undefined,
  TRootState,
  void,
  IGetOwnerInfoAction | IGetOwnerInfoResponseAction | IGetOwnerInfoFailAction
>;

type TGetVehiclesYearFunction = () => ThunkAction<
  Promise<IVehicleYear[] | null> | undefined,
  TRootState,
  void,
  IVehiclesYearPendingAction | IVehiclesYearResponseAction | IVehiclesYearFailAction
>;

type TGetVehiclesMakeFunction = (
  year: number,
) => ThunkAction<
  Promise<IVehicleMake[] | null> | undefined,
  TRootState,
  void,
  IVehiclesMakePendingAction | IVehiclesMakeResponseAction | IVehiclesMakeFailAction
>;

type TGetVehiclesMakeFilterFunction = () => ThunkAction<
  Promise<IVehicleMakeFilterResponse | null> | undefined,
  TRootState,
  void,
  | IVehiclesMakeFilterPendingAction
  | IVehiclesMakeFilterResponseAction
  | IVehiclesMakeFilterFailAction
>;

type TGetVehiclesModelsFilterFunction = (
  make: string,
) => ThunkAction<
  Promise<IVehicleModelsFilterResponse | null> | undefined,
  TRootState,
  void,
  | IVehiclesMakeFilterModelsPendingAction
  | IVehiclesMakeFilterModelsResponseAction
  | IVehiclesMakeFilterModelsFailAction
>;

type TGetVehiclesModelFunction = (
  year: number,
  make: string,
) => ThunkAction<
  Promise<IVehicle[] | null> | undefined,
  TRootState,
  void,
  IVehiclesModelPendingAction | IVehiclesModelResponseAction | IVehiclesModelFailAction
>;

type TGetVehicleInfoFunction = (
  id: string,
) => ThunkAction<
  Promise<IVehicle[] | null> | undefined,
  TRootState,
  void,
  IVehicleInfoPendingAction | IVehicleInfoResponseAction | IVehicleInfoFailAction
>;

type TSetSearchQuery = (
  query: string,
) => ThunkAction<void, TRootState, void, ISetSearchQueryAction>;

const doRentalListsMatch = (l1: IData[], l2: IData[]): boolean => {
  return l1.length === l2.length && l1.every((el, index) => el.id === l2[index]?.id);
};

const getRentalSearchResponse = async (
  parsedQuery: TQueryParams,
  currency: TCurrency,
  parsedPromoQuery: TQueryParams | null,
): Promise<TSearchData> => {
  const response = await searchApiRequest<ISearchRentals>('rentals', {
    queryParams: parsedQuery,
    rejectOnError: true,
    currency,
    promoQueryParams: parsedPromoQuery,
  });

  if (!response || !response.meta || !response.data) {
    const error = new Error('404');
    logger.captureExceptionWithDatadog(error, {
      search: error,
      message: 'Server response failed',
    });
    throw error;
  }
  const data: TSearchData = {
    data: response.data,
    meta: response.meta,
  };
  const suggestions = response.suggestions;
  if (response.meta.suggested === true || suggestions) {
    const nearbyRVs = suggestions?.nearby_rvs;
    const nearbyDeliverableRVs = suggestions?.nearby_deliverable_rvs;
    const randomStays = suggestions?.random_stays;
    if (nearbyRVs?.data?.length) {
      // TODO Remove me after updating BE --
      // BE may dupe results in data for legacy reasons. If so, set it to empty.
      if (doRentalListsMatch(nearbyRVs.data, data.data)) {
        data.data = [];
        data.meta.total = 0;
      }
      data.nearby = nearbyRVs;
    }
    if (nearbyDeliverableRVs?.data?.length) {
      data.nearby = nearbyDeliverableRVs;
    }
    if (randomStays?.data?.length) {
      data.randomStays = randomStays;
    }
  }

  if (suggestions && suggestions.nearby_campgrounds_for_delivery) {
    data.nearbyCampgroundsForDelivery = suggestions.nearby_campgrounds_for_delivery;
  }

  if (suggestions && suggestions.nearby_campgrounds) {
    data.nearbyCampgrounds = suggestions.nearby_campgrounds;
  }

  return data;
};

const getCampgroundSearchResponse = async (
  parsedQuery: TQueryParams,
  currency: TCurrency,
): Promise<TSearchData> => {
  const response = await searchApiRequest<ISearchCampground>('external-campgrounds', {
    queryParams: parsedQuery,
    rejectOnError: true,
    currency,
  });

  if (!response || !response.meta || !response.data) {
    const error = new Error('404');
    logger.captureExceptionWithDatadog(error, {
      search: error,
      message: 'Server response failed',
    });
    throw error;
  }
  const data: TSearchData = {
    campgrounds: {
      data: response.data,
    },
    meta: response.meta,
  };
  const suggestions = response.suggestions;
  if (response.meta.suggested === true || suggestions) {
    const nearbyDeliverableRVs = suggestions?.nearby_deliverable_rvs;
    const randomStays = suggestions?.random_stays;
    if (nearbyDeliverableRVs?.data?.length) {
      data.nearby = nearbyDeliverableRVs;
    }
    if (randomStays?.data?.length) {
      data.randomStays = randomStays;
    }
  }
  return data;
};

const setSearchQuery: TSetSearchQuery = query => {
  return (dispatch: ThunkDispatch<TRootState, void, IAction>) => {
    dispatch({
      type: SET_SEARCH_QUERY,
      payload: query,
    });
  };
};

export const getSearchResults: TSearchFunction =
  (query, recalculateMapViewport = false, promoQuery, onDoneCallback) =>
  async (dispatch, getState) => {
    const searchTimestamp = Date.now();
    dispatch<ISearchAction>({
      type: SEARCH_PENDING,
      payload: { searchTimestamp },
    });
    const currency = getCurrency(getState());
    const defaultQuery = {
      [ESearchFilters.PAGE_LIMIT]: getItemsPerPage().toString(),
      [ESearchFilters.SUGGESTED]: true,
      [ESearchFilters.OMIT_AGGREGATION]: true,
      [ESearchFilters.INCLUDE_TOTAL_PRICE_AGGREGATION]: false,
      [ESearchFilters.CAMPSITE_IS_ODN]: true,
    };

    // Add all default queries and override by non-falsey custom queries
    const parsedQuery: TQueryParams = filterSafeQuery({
      ...defaultQuery,
      ...query,
    });

    if (
      query[ESearchFilters.TOTAL_PRICE_ON_SRP_ENABLED] === 'true' &&
      query[ESearchFilters.DATE_FROM] &&
      query[ESearchFilters.DATE_TO]
    ) {
      parsedQuery[ESearchFilters.PRICE_TOTAL_MIN] = parsedQuery[ESearchFilters.PRICE_MIN];
      parsedQuery[ESearchFilters.PRICE_TOTAL_MAX] = parsedQuery[ESearchFilters.PRICE_MAX];
      delete parsedQuery[ESearchFilters.PRICE_MIN];
      delete parsedQuery[ESearchFilters.PRICE_MAX];
    }

    const parsedPromoQuery: TQueryParams | null = promoQuery
      ? filterSafeQuery({
          ...promoQuery,
        })
      : null;

    // FE needs delivery address set but if delivery is false, we want to omit this from search
    if (parsedQuery.delivery === 'false') {
      delete parsedQuery.delivery_address;
    }

    // Add extra conversion if totalPriceEnabled flag is set in query params
    if (
      query[ESearchFilters.TOTAL_PRICE_ON_SRP_ENABLED] === 'true' &&
      query[ESearchFilters.DATE_FROM] &&
      query[ESearchFilters.DATE_TO]
    ) {
      if (parsedQuery.sort === 'price') {
        parsedQuery.sort = 'total_price';
      } else if (parsedQuery.sort === '-price') {
        parsedQuery.sort = '-total_price';
      }
    }

    const isCampground = parsedQuery[ESearchFilters.RENTAL_CATEGORY] === ERentalType.CAMPGROUND;
    const showCampgroundData = !isProduction() && isCampground;
    dispatch(setSearchQuery(getSearchUrl('rentals', parsedQuery)));
    try {
      const getSearchResponse = showCampgroundData
        ? getCampgroundSearchResponse
        : getRentalSearchResponse;

      let data = await getSearchResponse(parsedQuery, currency, parsedPromoQuery);

      // In case of no results when applying a tags filter, we would like to be able to
      // have a fallback tags filter to still show some results to the user, even if
      // they are not exactly what they were looking for.
      // It is to be attached only manually for marketing purposes.
      if (
        !data.meta.total &&
        parsedQuery[ESearchFilters.FILTER_TAGS_SLUG] &&
        query[ESearchFallbackFilters.FILTER_TAGS_SLUG]
      ) {
        data = await getSearchResponse(
          {
            ...parsedQuery,
            [ESearchFilters.FILTER_TAGS_SLUG]: query[ESearchFallbackFilters.FILTER_TAGS_SLUG],
          },
          currency,
          parsedPromoQuery,
        );
      }

      dispatch<ISearchResponseAction>({
        type: SEARCH_RESPONSE,
        payload: { data, recalculateMapViewport, searchTimestamp },
      });
      // Updates meta preview with current results.
      dispatch<ISearchMetaResponseAction>({
        type: SEARCH_META_RESPONSE,
        payload: {
          data: data.meta,
          metaPreviewTimestamp: Date.now(),
        },
      });
    } catch (error) {
      logger.captureExceptionWithDatadog(error, {
        search: error,
      });

      dispatch<ISearchFailAction>({
        type: SEARCH_FAILURE,
        payload: { error, searchTimestamp },
        error: true,
      });
    } finally {
      if (onDoneCallback) {
        onDoneCallback();
      }
    }
  };

export const getSearchHistogramResults: TSearchHistogramFunction =
  (query, promoQuery, isPriceTotalOnSrpEnabled) => async (dispatch, getState) => {
    const histogramTimestamp = Date.now();

    try {
      dispatch<ISearchHistogramAction>({
        type: SEARCH_HISTOGRAM_PENDING,
        payload: { histogramTimestamp },
      });

      const currency = getCurrency(getState());

      const isCampground = query[ESearchFilters.RENTAL_CATEGORY] === ERentalType.CAMPGROUND;
      const showCampgroundData = !isProduction() && isCampground;
      const endpoint = showCampgroundData ? 'external-campgrounds' : 'rentals';

      const hasDates = !!(query[ESearchFilters.DATE_FROM] && query[ESearchFilters.DATE_TO]);

      let include_total_price_aggregation = false;

      // Only enable total price aggregation when:
      // 1. The feature flag (isPriceTotalOnSrpEnabled) is turned on
      // 2. Complete date range is provided (both start and end dates)
      // This ensures we only calculate total prices when we can do so accurately
      if (isPriceTotalOnSrpEnabled && hasDates) {
        include_total_price_aggregation = true;
      }

      if (
        query[ESearchFilters.TOTAL_PRICE_ON_SRP_ENABLED] === 'true' &&
        query[ESearchFilters.DATE_FROM] &&
        query[ESearchFilters.DATE_TO]
      ) {
        query[ESearchFilters.PRICE_TOTAL_MIN] = query[ESearchFilters.PRICE_MIN];
        query[ESearchFilters.PRICE_TOTAL_MAX] = query[ESearchFilters.PRICE_MAX];
        delete query[ESearchFilters.PRICE_MIN];
        delete query[ESearchFilters.PRICE_MAX];
      }

      const response = await searchApiRequest<ISearchCampground | ISearchRentals>(endpoint, {
        currency,
        queryParams: filterSafeQuery({
          ...query,
          include_total_price_aggregation,
          only: 'meta',
          omit_aggregation: false,
        }),
        rejectOnError: true,
        promoQueryParams: promoQuery,
      });

      if (!response || !response.meta) {
        throw new Error('404');
      }

      dispatch<ISearchHistogramResponseAction>({
        type: SEARCH_HISTOGRAM_RESPONSE,
        payload: { data: response.meta, histogramTimestamp },
      });
    } catch (error) {
      dispatch<ISearchHistogramFailAction>({
        type: SEARCH_HISTOGRAM_FAILURE,
        payload: { data: error, histogramTimestamp },
        error: true,
      });
    }
  };

const debouncedFetchMetaPreview = debounce(
  async ({
    query,
    dispatch,
    metaPreviewTimestamp,
    currency,
    promoQuery,
  }: {
    currency: TCurrency;
    query: Record<string, string | number | boolean | string[] | undefined>;
    dispatch: ThunkDispatch<TRootState, void, ISearchMetaResponseAction | ISearchMetaFailAction>;
    metaPreviewTimestamp: number;
    promoQuery?: TQueryParams | null;
  }) => {
    try {
      const isCampground = query[ESearchFilters.RENTAL_CATEGORY] === ERentalType.CAMPGROUND;
      const showCampgroundData = !isProduction() && isCampground;
      const endpoint = showCampgroundData ? 'external-campgrounds' : 'rentals';

      const response = await searchApiRequest<ISearchCampground | ISearchRentals>(endpoint, {
        currency,
        queryParams: filterSafeQuery({
          ...query,
          omit_aggregation: true,
          include_total_price_aggregation: false,
          only: 'meta',
        }),
        rejectOnError: true,
        promoQueryParams: promoQuery,
      });

      if (!response || !response.meta) {
        throw new Error('404');
      }

      dispatch<ISearchMetaResponseAction>({
        type: SEARCH_META_RESPONSE,
        payload: { data: response.meta, metaPreviewTimestamp },
      });
    } catch (error) {
      dispatch<ISearchMetaFailAction>({
        type: SEARCH_META_FAILURE,
        payload: { data: error, metaPreviewTimestamp },
        error: true,
      });
    }
  },
  650,
);

export const getSearchMetaResults: TSearchMetaFunction =
  (filters, ignorePersitedFilters = true) =>
  async (dispatch, getState) => {
    if (isEmpty(filters)) return null;

    const currency = getCurrency(getState());
    const metaPreviewTimestamp = Date.now();
    dispatch<ISearchMetaAction>({
      type: SEARCH_META_PENDING,
      payload: { metaPreviewTimestamp },
    });
    const existingParams = getState().queryParams;
    // Get search form instead of filters param to handle multi-filter modals (amenities, more and mobile)
    const searchForm = getState().searchForm;
    const previewFilters = Object.entries(searchForm.filters).reduce<
      Record<string, string | number | boolean | undefined>
    >((acc, cur) => {
      if (ignorePersitedFilters && PERSIST_FORM_FILTERS.includes(cur[0] as ESearchFilters))
        return acc;
      const value = Array.isArray(cur[1]) ? cur[1].join(',') : cur[1] || undefined;
      const key = String(cur[0]);
      acc[key] = value;
      return acc;
    }, {});
    const query = { ...existingParams, ...previewFilters };

    if (
      query[ESearchFilters.TOTAL_PRICE_ON_SRP_ENABLED] === 'true' &&
      query[ESearchFilters.DATE_FROM] &&
      query[ESearchFilters.DATE_TO]
    ) {
      query[ESearchFilters.PRICE_TOTAL_MIN] = query[ESearchFilters.PRICE_MIN];
      query[ESearchFilters.PRICE_TOTAL_MAX] = query[ESearchFilters.PRICE_MAX];
      delete query[ESearchFilters.PRICE_MIN];
      delete query[ESearchFilters.PRICE_MAX];
    }

    const isPromoPage = router.pathname.includes(PAGE_PATHNAME[EPage.PROMOS]);
    let promoQuery = null;
    if (isPromoPage) {
      promoQuery = getState().promoQueryParams;
    }

    return debouncedFetchMetaPreview({
      query,
      dispatch,
      metaPreviewTimestamp,
      currency,
      promoQuery,
    });
  };

const actionIsValid = (dataTimestamp: number, stateTimestamp: number) => {
  return dataTimestamp >= stateTimestamp;
};

export const applySearchFilter: TApplySearchFilterFunction =
  (
    filter,
    ignoreRecalculateMapViewport = false,
    dryRun = false,
    externalRouting = false,
    afterRedirectCallback,
    ignoreExistingQueryParams = false,
  ) =>
  (dispatch, getState) => {
    const existingParams = getState().queryParams;
    // Later properties overwrite existing properties.
    // i.e. existingParams.foo = 42, filter.foo = 486
    // then newParams.foo = 486
    const newParams = { ...(ignoreExistingQueryParams ? {} : existingParams), ...filter };
    Object.keys(newParams).forEach(
      key => (newParams[key] === 'undefined' || !newParams[key]) && delete newParams[key],
    );

    // Do not re-route from promo and campground rental pages
    const isPromoPage = router.pathname.includes(PAGE_PATHNAME[EPage.PROMOS]);
    const isCampgroundPage = router.pathname.includes(PAGE_PATHNAME[EPage.CAMPGROUND_SEARCH]);
    const isRVBundlesPage = router.pathname.includes(PAGE_PATHNAME[EPage.RV_BUNDLES]);

    // If we're not already on the search page, do manual routing
    // @TODO: Remove this once old search page is deprecated
    if (
      !router.pathname.includes(PAGE_PATHNAME[EPage.SEARCH]) &&
      !isPromoPage &&
      !isCampgroundPage &&
      !isRVBundlesPage
    ) {
      externalRouting
        ? (window.location.href = `${PAGE_PATHNAME[EPage.SEARCH]}?${new URLSearchParams(
            newParams as Record<string, string>,
          ).toString()}`)
        : router.push({
            pathname: PAGE_PATHNAME[EPage.SEARCH],
            query: newParams,
          });
      if (externalRouting && afterRedirectCallback) {
        afterRedirectCallback();
      }

      return null;
    }

    // If some map-related filter actually _changed_, we should recalculate the map viewport.
    const recalculateMapViewport =
      !ignoreRecalculateMapViewport && UPDATE_MAP_FILTERS.some(f => Boolean(filter[f]));

    let customPathname = '';
    let promoQuery = null;
    if (isPromoPage) {
      const promoSlug = router.query.promoSlug;
      const slug = Array.isArray(promoSlug) ? promoSlug.join('/') : promoSlug;
      customPathname = `${PAGE_PATHNAME[EPage.PROMOS]}/${slug}`;
      promoQuery = getState().promoQueryParams;
    }

    if (isCampgroundPage) {
      const { location, slug } = router.query;
      customPathname = `${PAGE_PATHNAME[EPage.CAMPGROUND_SEARCH]}/${location}/${slug}`;
    }

    if (isRVBundlesPage) {
      const { slug } = router.query;
      customPathname = `${PAGE_PATHNAME[EPage.RV_BUNDLES]}/${slug}`;
    }

    // Set route and move on. Queryparams should only be source of truth on initial page load.
    router.push(
      {
        pathname: customPathname ? customPathname : PAGE_PATHNAME[EPage.SEARCH],
        query: newParams,
      },
      undefined,
      { shallow: true },
    );

    if (dryRun) return null;
    return dispatch(
      getSearchResults(newParams, recalculateMapViewport, promoQuery, afterRedirectCallback),
    );
  };

export const getOwnerInfo: TGetOwnerInfoFunction = (ownerId?: string) => dispatch => {
  if (!ownerId) {
    dispatch({
      type: SET_OWNER_INFO_RESPONSE,
      payload: undefined,
    });

    return undefined;
  }

  dispatch({
    type: SET_OWNER_INFO_PENDING,
  });

  return new Promise(() =>
    searchApiRequest<IUserResponse>(`users/${ownerId}`)
      .then(response => {
        if (!response?.data) {
          throw new Error('The user has not been found.');
        }

        return dispatch({
          type: SET_OWNER_INFO_RESPONSE,
          payload: response.data,
        });
      })
      .catch(error => {
        return dispatch<IGetOwnerInfoFailAction>({
          type: SET_OWNER_INFO_FAILURE,
          payload: error,
          error: true,
        });
      }),
  );
};

export const unsetSearchFilter: TUnsetSearchFilterFunction =
  (filter: ESearchFilters[], applyFilters: Record<string, any> = {}) =>
  (dispatch, getState) => {
    const existingParams = getState().queryParams;
    const existingParamsKeys = Object.keys(existingParams) as ESearchFilters[];

    const unsetParamsInExistingFilters = filter.some(f => existingParamsKeys.includes(f));
    if (!unsetParamsInExistingFilters) return null;

    const { [ESearchFilters.PAGE_LIMIT]: pageLimit } = existingParams;
    const itemsPerPage = getItemsPerPage(pageLimit);
    const resetPageParams: Record<string, string> = {
      [ESearchFilters.PAGE_OFFSET]: String(0),
      [ESearchFilters.PAGE_LIMIT]: String(itemsPerPage),
    };

    const newParams = { ...existingParams, ...resetPageParams, ...applyFilters };

    filter.forEach(val => {
      delete newParams[val];
    });

    if (isEqual(existingParams, newParams)) {
      return null;
    }

    dispatch(setQueryParam(newParams));

    const isPromoPage = router.pathname.includes(PAGE_PATHNAME[EPage.PROMOS]);
    let promoPathname = '';
    let promoQuery = null;
    if (isPromoPage) {
      const promoSlug = router.query.promoSlug;
      const slug = Array.isArray(promoSlug) ? promoSlug.join('/') : promoSlug;
      promoPathname = `${PAGE_PATHNAME[EPage.PROMOS]}/${slug}`;
      promoQuery = getState().promoQueryParams;
    }

    router.push(
      {
        pathname: isPromoPage ? promoPathname : router.pathname,
        query: newParams,
      },
      undefined,
      { shallow: true },
    );
    return dispatch(getSearchResults(newParams, false, promoQuery));
  };

export const setSelectedFilter = (payload: EFilterCategories | null): ISetSelectedFilterAction => ({
  type: SET_SELECTED_FILTER,
  payload,
});

export const triggerStationaryDeliveryFilter = (
  payload: boolean,
): ITriggerStationaryDeliveryFilterAction => ({
  type: TRIGGER_STATIONARY_DELIVERY_FILTER,
  payload,
});

export const triggerDeliveryFilterFromAd = (
  payload: boolean,
): ITriggerDeliveryFromBannerAction => ({
  type: TRIGGER_DELIVERY_FILTER_FROM_AD,
  payload,
});

const TOWING_URL = `${getCoreApi()}/towing_info`;

export const getVehiclesMakesForMakeFilter: TGetVehiclesMakeFilterFunction = () => dispatch => {
  const url = `${getCoreApi()}/info/vehicles/makes`;

  dispatch({
    type: GET_VEHICLES_MAKE_FILTER_PENDING,
  });

  return new Promise((resolve, reject) =>
    apiRequest<IVehicleMakeFilterResponse>({ url: url, method: 'GET' }, true)
      .then(response => {
        dispatch({
          type: GET_VEHICLES_MAKE_FILTER_RESPONSE,
          payload: response,
        });
        return resolve(response);
      })
      .catch(error => {
        dispatch<IVehiclesMakeFilterFailAction>({
          type: GET_VEHICLES_MAKE_FILTER_FAILURE,
          payload: error,
          error: true,
        });
        return reject(error);
      }),
  );
};

export const getVehiclesModelsForMakeFilter: TGetVehiclesModelsFilterFunction =
  (make: string) => dispatch => {
    dispatch({
      type: GET_VEHICLES_MAKE_FILTER_MODELS_PENDING,
      make,
    });

    return apiRequest<IVehicleModelsFilterResponse>(
      { url: `${getCoreApi()}/info/vehicles/models?make=${make}`, method: 'GET' },
      true,
    )
      .then(response => {
        dispatch({
          type: GET_VEHICLES_MAKE_FILTER_MODELS_RESPONSE,
          make,
          payload: response,
        });

        return response;
      })
      .catch(error => {
        dispatch<IVehiclesMakeFilterModelsFailAction>({
          type: GET_VEHICLES_MAKE_FILTER_MODELS_FAILURE,
          make,
          payload: error,
          error: true,
        });

        return error;
      });
  };

export const getVehiclesYear: TGetVehiclesYearFunction = () => dispatch => {
  const url = TOWING_URL;

  dispatch({
    type: GET_VEHICLES_YEAR_PENDING,
  });

  return new Promise((resolve, reject) =>
    apiRequest<IVehicleYear[]>({ url: url, method: 'GET' }, true)
      .then(response => {
        dispatch({
          type: GET_VEHICLES_YEAR_RESPONSE,
          payload: response,
        });
        return resolve(response);
      })
      .catch(error => {
        dispatch<IVehiclesYearFailAction>({
          type: GET_VEHICLES_YEAR_FAILURE,
          payload: error,
          error: true,
        });
        return reject(error);
      }),
  );
};

export const getVehiclesMake: TGetVehiclesMakeFunction = (year: number) => dispatch => {
  const url = `${TOWING_URL}?year=${year}`;

  dispatch({
    type: GET_VEHICLES_MAKE_PENDING,
  });

  return new Promise((resolve, reject) =>
    apiRequest<IVehicleMake[]>({ url: url, method: 'GET' }, true)
      .then(response => {
        dispatch({
          type: GET_VEHICLES_MAKE_RESPONSE,
          payload: response,
        });
        return resolve(response);
      })
      .catch(error => {
        dispatch<IVehiclesMakeFailAction>({
          type: GET_VEHICLES_MAKE_FAILURE,
          payload: error,
          error: true,
        });
        return reject(error);
      }),
  );
};

export const getVehiclesModel: TGetVehiclesModelFunction =
  (year: number, make: string) => dispatch => {
    const url = `${TOWING_URL}?year=${year}&make=${make}`;

    dispatch({
      type: GET_VEHICLES_MODEL_PENDING,
    });

    return new Promise((resolve, reject) =>
      apiRequest<IVehicle[]>({ url: url, method: 'GET' }, true)
        .then(response => {
          dispatch({
            type: GET_VEHICLES_MODEL_RESPONSE,
            payload: response,
          });
          return resolve(response);
        })
        .catch(error => {
          dispatch<IVehiclesModelFailAction>({
            type: GET_VEHICLES_MODEL_FAILURE,
            payload: error,
            error: true,
          });
          return reject(error);
        }),
    );
  };

export const getVehicleInfo: TGetVehicleInfoFunction = (id: string) => dispatch => {
  const url = `${TOWING_URL}?id=${id}`;

  dispatch({
    type: GET_VEHICLE_INFO_PENDING,
  });

  return new Promise((resolve, reject) =>
    apiRequest<IVehicle[]>({ url: url, method: 'GET' }, true)
      .then(response => {
        if (!response || !response[0]) {
          throw Error();
        }
        dispatch({
          type: GET_VEHICLE_INFO_RESPONSE,
          payload: response[0],
        });
        return resolve(response);
      })
      .catch(error => {
        dispatch<IVehicleInfoFailAction>({
          type: GET_VEHICLE_INFO_FAILURE,
          payload: error,
          error: true,
        });
        return reject(error);
      }),
  );
};

interface IState {
  data?: IData[] | null;
  campgrounds?: {
    data?: ICampgroundData[];
  };
  error?: string;
  errorHistogram?: string;
  errorMeta?: string;
  errorOwnerInfo?: string;
  isFilterOpen: boolean;
  isLoading: boolean;
  isOwnerInfoLoading?: boolean;
  histogramTimestamp: number | null;
  isMetaLoading?: boolean;
  isHistogramLoading?: boolean;
  meta?: IMeta | null;
  metaPreview?: IMeta | null;
  metaPreviewTimestamp: number | null;
  nearby?: {
    data?: IData[];
    meta?: IMeta;
  };
  randomStays?: {
    data?: IData[];
    meta?: IMeta;
  };
  ownerInfo?: IUserResponseData;
  recalculateMapViewport: boolean;
  recentSearches: IRecentSearch[];
  searchTimestamp: number | null;
  selectedFilter: EFilterCategories | null;
  vehiclesYear?: IVehicleYear[];
  vehiclesMake?: IVehicleMake[];
  vehiclesMakeFilter?: IVehicleMakeFilterResponse | null;
  vehiclesMakeFilterModels?: Record<
    string,
    {
      data?: IVehicleModelsFilterResponse | null;
      pending?: boolean;
      error?: string;
    }
  > | null;
  vehiclesModel?: IVehicle[];
  vehicleInfo?: IVehicle;
  vehiclesLoading: boolean;
  nearbyCampgroundsForDelivery?: {
    data: ICampgroundData[];
    meta: IMeta;
  };
  nearbyCampgrounds?: {
    data: ICampgroundData[];
    meta: IMeta;
  };
  isStationaryDeliveryOpen?: boolean;
  triggerDeliveryFilterFromAd?: boolean;
  searchQuery: string;
}

export const initialState: IState = {
  data: null,
  isFilterOpen: false,
  isLoading: true,
  histogramTimestamp: null,
  meta: null,
  metaPreview: null,
  metaPreviewTimestamp: null,
  recalculateMapViewport: false,
  recentSearches: [],
  searchTimestamp: null,
  selectedFilter: null,
  vehiclesYear: [],
  vehiclesMake: [],
  vehiclesMakeFilter: null,
  vehiclesMakeFilterModels: null,
  vehiclesModel: [],
  vehicleInfo: undefined,
  vehiclesLoading: false,
  isHistogramLoading: true,
  isStationaryDeliveryOpen: false,
  triggerDeliveryFilterFromAd: false,
  searchQuery: '',
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SEARCH_PENDING: {
      const { searchTimestamp } = action.payload;
      return {
        ...state,
        error: undefined,
        isLoading: true,
        recalculateMapViewport: false,
        searchTimestamp,
      };
    }
    case SEARCH_RESPONSE: {
      const { data, recalculateMapViewport, searchTimestamp: dataSearchTimestamp } = action.payload;

      const { searchTimestamp: stateSearchTimestamp } = state;

      if (actionIsValid(dataSearchTimestamp, stateSearchTimestamp || 0)) {
        const nextState = {
          ...state,
          meta: data.meta,
          error: undefined,
          isLoading: false,
          nearby: data.nearby,
          randomStays: data.randomStays,
          recalculateMapViewport,
          nearbyCampgroundsForDelivery: data.nearbyCampgroundsForDelivery,
          nearbyCampgrounds: data.nearbyCampgrounds,
        };
        if (isCampgroundSearchData(data)) {
          nextState.campgrounds = data.campgrounds;
          nextState.data = undefined;
        } else {
          nextState.campgrounds = undefined;
          nextState.data = data.data;
        }
        return nextState;
      }

      return state;
    }
    case SEARCH_FAILURE: {
      const { searchTimestamp: dataSearchTimestamp, error } = action.payload;

      const { searchTimestamp: stateSearchTimestamp } = state;

      if (actionIsValid(dataSearchTimestamp, stateSearchTimestamp || 0)) {
        return {
          ...state,
          data: [],
          error,
          isLoading: false,
          meta: null,
          recalculateMapViewport: false,
        };
      }

      return state;
    }
    case SEARCH_HISTOGRAM_PENDING: {
      const { histogramTimestamp: stateHistogramTimestamp } = state;
      return {
        ...state,
        error: undefined,
        isHistogramLoading: true,
        stateHistogramTimestamp,
      };
    }
    case SEARCH_HISTOGRAM_RESPONSE: {
      const { data, histogramTimestamp: dataHistogramTimestamp } = action.payload;
      const { histogramTimestamp: stateHistogramTimestamp } = state;

      if (actionIsValid(dataHistogramTimestamp, stateHistogramTimestamp || 0)) {
        const hasTotalPricesData =
          data.total_prices_histogram && data.total_prices_histogram.data !== null;

        return {
          ...state,
          meta: state.meta && {
            ...state.meta,
            price_histogram: hasTotalPricesData
              ? data.total_prices_histogram
              : data.price_histogram,
          },
          isHistogramLoading: false,
          errorHistogram: undefined,
        };
      }

      return state;
    }
    case SEARCH_HISTOGRAM_FAILURE: {
      const { data, histogramTimestamp: dataHistogramTimestamp } = action.payload;
      const { histogramTimestamp: stateHistogramTimestamp } = state;

      if (actionIsValid(dataHistogramTimestamp, stateHistogramTimestamp || 0)) {
        return {
          ...state,
          errorHistogram: data,
          isHistogramLoading: false,
        };
      }

      return state;
    }
    case SEARCH_META_PENDING: {
      const { metaPreviewTimestamp } = action.payload;
      return {
        ...state,
        errorMeta: undefined,
        isMetaLoading: true,
        metaPreviewTimestamp,
      };
    }
    case SEARCH_META_RESPONSE: {
      const { data, metaPreviewTimestamp: dataMetaPreviewTimestamp } = action.payload;
      const { metaPreviewTimestamp: stateMetaPreviewTimestamp } = state;

      if (actionIsValid(dataMetaPreviewTimestamp, stateMetaPreviewTimestamp || 0)) {
        return {
          ...state,
          metaPreview: data,
          errorMeta: undefined,
          isMetaLoading: false,
        };
      }

      return state;
    }
    case SEARCH_META_FAILURE: {
      const { data, metaPreviewTimestamp: dataMetaPreviewTimestamp } = action.payload;
      const { metaPreviewTimestamp: stateMetaPreviewTimestamp } = state;

      if (actionIsValid(dataMetaPreviewTimestamp, stateMetaPreviewTimestamp || 0)) {
        return {
          ...state,
          errorMeta: data,
          isMetaLoading: false,
          metaPreview: null,
        };
      }

      return state;
    }
    case SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case TOGGLE_FILTER:
      return {
        ...state,
        isFilterOpen: action.payload,
      };
    case SET_OWNER_INFO_PENDING:
      return {
        ...state,
        ownerInfo: undefined,
        isOwnerInfoLoading: true,
        errorOwnerInfo: undefined,
      };
    case SET_OWNER_INFO_RESPONSE:
      return {
        ...state,
        ownerInfo: action.payload,
        isOwnerInfoLoading: false,
        errorOwnerInfo: undefined,
      };
    case SET_OWNER_INFO_FAILURE:
      return {
        ...state,
        ownerInfo: undefined,
        isOwnerInfoLoading: false,
        errorOwnerInfo: action.payload,
      };

    case GET_VEHICLES_YEAR_PENDING:
      return {
        ...state,
        vehiclesYear: undefined,
        vehiclesYearLoading: true,
        errorVehiclesYear: undefined,
      };
    case GET_VEHICLES_YEAR_RESPONSE:
      return {
        ...state,
        vehiclesYear: action.payload,
        vehiclesYearLoading: false,
        errorVehiclesYear: undefined,
      };
    case GET_VEHICLES_YEAR_FAILURE:
      return {
        ...state,
        vehiclesYearLoading: false,
        errorVehiclesYear: action.payload,
      };

    case GET_VEHICLES_MAKE_PENDING:
      return {
        ...state,
        vehiclesMake: undefined,
        vehiclesMakeLoading: true,
        errorVehiclesMake: undefined,
      };
    case GET_VEHICLES_MAKE_RESPONSE:
      return {
        ...state,
        vehiclesMake: action.payload,
        vehiclesMakeLoading: false,
        errorVehiclesMake: undefined,
      };
    case GET_VEHICLES_MAKE_FAILURE:
      return {
        ...state,
        vehiclesMakeLoading: false,
        errorVehiclesMake: action.payload,
      };

    case GET_VEHICLES_MAKE_FILTER_PENDING:
      return {
        ...state,
        vehiclesMakeFilter: undefined,
        vehiclesMakeLoadingFilter: true,
        errorVehiclesMakeFilter: undefined,
      };
    case GET_VEHICLES_MAKE_FILTER_RESPONSE:
      return {
        ...state,
        vehiclesMakeFilter: action.payload,
        vehiclesMakeLoadingFilter: false,
        errorVehiclesMakeFilter: undefined,
      };
    case GET_VEHICLES_MAKE_FILTER_FAILURE:
      return {
        ...state,
        vehiclesMakeLoadingFilter: false,
        errorVehiclesMakeFilter: action.payload,
      };

    case GET_VEHICLES_MAKE_FILTER_MODELS_PENDING:
      return {
        ...state,
        vehiclesMakeFilterModels: {
          ...state.vehiclesMakeFilterModels,
          [action.make]: {
            data: undefined,
            pending: true,
            error: undefined,
          },
        },
      };
    case GET_VEHICLES_MAKE_FILTER_MODELS_RESPONSE:
      return {
        ...state,
        vehiclesMakeFilterModels: {
          ...state.vehiclesMakeFilterModels,
          [action.make]: {
            data: action.payload,
            pending: false,
            error: undefined,
          },
        },
      };
    case GET_VEHICLES_MAKE_FILTER_MODELS_FAILURE:
      return {
        ...state,
        vehiclesMakeFilterModels: {
          ...state.vehiclesMakeFilterModels,
          [action.make]: {
            data: undefined,
            pending: false,
            error: action.payload,
          },
        },
      };

    case GET_VEHICLES_MODEL_PENDING:
      return {
        ...state,
        vehiclesModel: undefined,
        vehiclesLoadingModel: true,
        errorVehiclesModel: undefined,
      };
    case GET_VEHICLES_MODEL_RESPONSE:
      return {
        ...state,
        vehiclesModel: action.payload,
        vehiclesLoadingModel: false,
        errorVehiclesModel: undefined,
      };
    case GET_VEHICLES_MODEL_FAILURE:
      return {
        ...state,
        vehiclesLoadingModel: false,
        errorVehiclesModel: action.payload,
      };

    case GET_VEHICLE_INFO_PENDING:
      return {
        ...state,
        vehicleInfo: undefined,
        vehiclesLoadingModel: true,
        errorVehiclesModel: undefined,
      };
    case GET_VEHICLE_INFO_RESPONSE:
      return {
        ...state,
        vehicleInfo: action.payload,
        vehiclesLoadingModel: false,
        errorVehiclesModel: undefined,
      };
    case GET_VEHICLE_INFO_FAILURE:
      return {
        ...state,
        vehiclesLoadingModel: false,
        errorVehiclesModel: action.payload,
      };

    case TRIGGER_STATIONARY_DELIVERY_FILTER:
      return {
        ...state,
        isStationaryDeliveryOpen: action.payload,
      };
    case TRIGGER_DELIVERY_FILTER_FROM_AD:
      return {
        ...state,
        triggerDeliveryFilterFromAd: action.payload,
      };

    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };

    default:
      return state;
  }
}
