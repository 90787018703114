import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { ThunkAction } from 'redux-thunk';

import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import { getCoreApi } from '@/utility/getCoreApi';
import { IAction } from '@/utility/redux/action';
import { getAuthToken } from '@/utility/session';
import { triggerLoginModal } from '@/utility/triggerLoginModal';

import { getSavedRentals, ISetSavedRentalsAction, setSavedRentals } from './savedRentals';
const ADD_FAVORITE_SUCCESS = 'favorites/ADD_FAVORITE_SUCCESS';
const ADD_FAVORITE_FAILURE = 'favorites/ADD_FAVORITE_FAILURE';
const DELETE_FAVORITE_SUCCESS = 'favorites/DELETE_FAVORITE_SUCCESS';
const DELETE_FAVORITE_FAILURE = 'favorites/DELETE_FAVORITE_FAILURE';
const SET_FAVORITES = 'favorites/SET_FAVORITES';

export interface IFavorite {
  added_at: string;
  id: number;
  rental_id: number;
  wishlist_creator_id?: number;
  wishlist_name?: string;
  wishlist_id?: number;
}

interface IAddFavoriteSucessAction extends IAction {
  type: typeof ADD_FAVORITE_SUCCESS;
}

interface IAddFavoriteFailureAction extends IAction {
  type: typeof ADD_FAVORITE_FAILURE;
  payload: AxiosError;
}

interface IDeleteFavoriteSucessAction extends IAction {
  type: typeof DELETE_FAVORITE_SUCCESS;
}

interface IDeleteFavoriteFailureAction extends IAction {
  type: typeof DELETE_FAVORITE_FAILURE;
  payload: AxiosError;
}

interface ISetFavoritesAction extends IAction {
  type: typeof SET_FAVORITES;
  payload: IFavorite[];
}

type TAction = ISetFavoritesAction | IAddFavoriteFailureAction | IAddFavoriteSucessAction;

const addFavoriteSuccess = (): IAddFavoriteSucessAction => ({
  type: ADD_FAVORITE_SUCCESS,
});

const addFavoriteFailure = (payload: AxiosError): IAddFavoriteFailureAction => ({
  type: ADD_FAVORITE_FAILURE,
  payload,
});

const deleteFavoriteSuccess = (): IDeleteFavoriteSucessAction => ({
  type: DELETE_FAVORITE_SUCCESS,
});

const deleteFavoriteFailure = (payload: AxiosError): IDeleteFavoriteFailureAction => ({
  type: DELETE_FAVORITE_FAILURE,
  payload,
});

export const setFavorites = (payload: IFavorite[]): ISetFavoritesAction => ({
  type: SET_FAVORITES,
  payload,
});

interface IFavoritesResponse {
  added_at: string;
  id: number;
  rental_id: number;
  user_id: number;
}

export const addToFavorites =
  (
    rentalId: number,
    refetchSavedRentals?: boolean,
  ): ThunkAction<
    Promise<null | void>,
    TRootState,
    void,
    IAddFavoriteSucessAction | IAddFavoriteFailureAction
  > =>
  async dispatch => {
    const authToken = getAuthToken();

    if (!authToken) {
      triggerLoginModal(0);
      return null;
    }

    const data = {
      rental_id: rentalId,
      added_at: dayjs().format('YYYY-MM-DD'),
    };

    const headers = { authorization: `Token=${authToken}` };

    const requestUrl = `${getCoreApi()}/favorites`;
    try {
      await apiRequest<IFavoritesResponse>({
        url: requestUrl,
        data,
        method: 'POST',
        headers,
      });
      dispatch(addFavoriteSuccess());
      dispatch(getFavorites());

      if (refetchSavedRentals) {
        dispatch(getSavedRentals());
      }
    } catch (error) {
      dispatch(addFavoriteFailure(error));
    }
  };

export const removeFromFavorites =
  (
    rentalId: number,
  ): ThunkAction<
    void,
    TRootState,
    void,
    IDeleteFavoriteSucessAction | IDeleteFavoriteFailureAction | ISetSavedRentalsAction
  > =>
  async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken();
    const favorite = state.favorites.data.find(favorite => favorite.rental_id === rentalId);
    const updatedSavedRentals = state.savedRentals.data.filter(rental => rental.id !== rentalId);

    if (!authToken || !favorite) {
      return null;
    }

    const headers = { authorization: `Token=${authToken}` };

    const requestUrl = `${getCoreApi()}/favorites/${favorite.id}`;
    try {
      await apiRequest<IFavoritesResponse>(
        {
          url: requestUrl,
          method: 'DELETE',
          headers,
        },
        true,
      );
      dispatch(deleteFavoriteSuccess());
      dispatch(setSavedRentals(updatedSavedRentals));
      dispatch(getFavorites());
    } catch (error) {
      dispatch(deleteFavoriteFailure(error));
    }
  };

export const getFavorites =
  (): ThunkAction<Promise<ISetFavoritesAction>, TRootState, void, ISetFavoritesAction> =>
  async dispatch => {
    const authToken = getAuthToken();

    if (!authToken) {
      return dispatch(setFavorites([]));
    }

    const requestUrl = `${getCoreApi()}/favorites`;

    const headers = { authorization: `Token=${authToken}` };

    const response = await apiRequest<IFavoritesResponse[]>({
      url: requestUrl,
      headers,
    });

    return dispatch(setFavorites(response || []));
  };

interface IState {
  data: IFavorite[];
}

export const initialState: IState = {
  data: [],
};

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_FAVORITES:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
